<template>
  <div class="flix-html-small flix-html-code">
    <bookingflix-calendar-items :settings='settings'></bookingflix-calendar-items>
  </div>
</template>

<script>
export default {
  props: { entry: Object },
  data () {
    return {
      settings: JSON.stringify({ view: 'timer', date: [this.entry.begin] })
    }
  }
}
</script>
